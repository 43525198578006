import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {
  "layout": "post",
  "title": "Rebels Make History",
  "author": "Christina",
  "date": "2020-03-25T07:11:55.000Z",
  "categories": ["Diversity and Inclusion", "empowerment", "Women's History Month"],
  "slug": "rebels-make-history",
  "draft": false,
  "meta_title": "Rebels Make History",
  "cover": "../../images/wp_blog_images/82457429_10162759603370297_7894763383176560640_o.jpg"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`With Women’s History Month in full swing, I was brought back to an event I recently attended.`}</p>
    <p>{`I spent an afternoon with my daughter’s gifted and talented program listening to children perform as their favorite genius. Many of the students were representing historical women in history, to include my daughter. She chose Marie Curie. Each child dressed up as the historical figure and shared a short speech to share what they believed was important about the person.`}</p>
    <p>{`As I walked from student to student, I felt like I was in a museum, pressing a button to learn about history. One remarkable student brought a button for people to press. It was a fulfilling experience to learn about historical figures and to have the opportunity to mentor others.`}</p>
    <span {...{
      "className": "gatsby-resp-image-wrapper",
      "style": {
        "position": "relative",
        "display": "block",
        "marginLeft": "auto",
        "marginRight": "auto",
        "maxWidth": "1280px"
      }
    }}>{`
      `}<span parentName="span" {...{
        "className": "gatsby-resp-image-background-image",
        "style": {
          "paddingBottom": "75.9375%",
          "position": "relative",
          "bottom": "0",
          "left": "0",
          "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAPABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAMEBf/EABUBAQEAAAAAAAAAAAAAAAAAAAEC/9oADAMBAAIQAxAAAAFqILpvTMcX/8QAGBAAAgMAAAAAAAAAAAAAAAAAAQIAAxL/2gAIAQEAAQUCsIrgc6VxkFpajqmln//EABYRAQEBAAAAAAAAAAAAAAAAAAABEv/aAAgBAwEBPwGMP//EABURAQEAAAAAAAAAAAAAAAAAAAAR/9oACAECAQE/AUf/xAAcEAACAgIDAAAAAAAAAAAAAAAAAREhAhIxYZH/2gAIAQEABj8CaXg1BeSF2zbJxfBctn//xAAaEAEAAwEBAQAAAAAAAAAAAAABABEhMUFx/9oACAEBAAE/IWrefZFMAhuwYLR22LdeBMUnk3LdLOs//9oADAMBAAIAAwAAABDg3//EABYRAQEBAAAAAAAAAAAAAAAAAAEREP/aAAgBAwEBPxAlmP/EABYRAQEBAAAAAAAAAAAAAAAAAAABcf/aAAgBAgEBPxCxp//EAB0QAQEAAgIDAQAAAAAAAAAAAAERACExUUFhgfD/2gAIAQEAAT8Q20wNFPR1gISCEieDX3JCNIb9xyuwhlfzlvAjdXgaG8mCoKQ31zn/2Q==')",
          "backgroundSize": "cover",
          "display": "block"
        }
      }}></span>{`
  `}<img parentName="span" {...{
        "className": "gatsby-resp-image-image",
        "alt": "82457429 10162759603370297 7894763383176560640 o",
        "title": "82457429 10162759603370297 7894763383176560640 o",
        "src": "/static/0ea8e51c2af502da211deb308029166f/eea4a/82457429_10162759603370297_7894763383176560640_o.jpg",
        "srcSet": ["/static/0ea8e51c2af502da211deb308029166f/cb69c/82457429_10162759603370297_7894763383176560640_o.jpg 320w", "/static/0ea8e51c2af502da211deb308029166f/c08c5/82457429_10162759603370297_7894763383176560640_o.jpg 640w", "/static/0ea8e51c2af502da211deb308029166f/eea4a/82457429_10162759603370297_7894763383176560640_o.jpg 1280w", "/static/0ea8e51c2af502da211deb308029166f/029b7/82457429_10162759603370297_7894763383176560640_o.jpg 1813w"],
        "sizes": "(max-width: 1280px) 100vw, 1280px",
        "style": {
          "width": "100%",
          "height": "100%",
          "margin": "0",
          "verticalAlign": "middle",
          "position": "absolute",
          "top": "0",
          "left": "0"
        },
        "loading": "lazy",
        "decoding": "async"
      }}></img>{`
    `}</span>
    <p>{`Thinking about the history lesson I received that afternoon, I realized that these historical figures had rebelliousness in common. The term rebellious often has a negative connotation in our society. In reality, nothing changes without rebellion. At its purest form, being rebellious is showing a desire to resist authority, control, or convention. This is how people make history. `}</p>
    <p>{`I have called myself a rebel many times and the looks I get are not great. There is a lot of judgment surrounding the idea of being a rebel. However, when we look back in history, we are in awe of the rebels; those that have chosen to go against a convention because they believed in something greater. `}</p>
    <p>{`It takes great courage to go against convention and I could not do it without the support of my four daughters and sisters from another mister. In return, I encourage them to stand up for what they believe in and chase their dreams.`}</p>
    <p>{`I am not sure why we wait until “history” determines a rebel be worthy of praise instead of praising the rebellious every day for their ordinary act of courage. `}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      